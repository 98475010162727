<template>
  <div class="h-auto">
    <div class="hero">
      <div class="container">
        <div
          class="wow fadeIn hero_info"
          data-wow-duration="4s"
        >
          <h1>
            Atlantis<br>
            is<br>
            HERE
          </h1>
          <div class="d-flex">
            <a
              href="#"
              class="btn btn-outline-light btn-lg"
              data-bs-toggle="modal"
              data-bs-target="#connectModal"
            >Connect</a>
            <a
              href="detail"
              class="btn btn-primary btn-lg ms-3"
            >Mint</a>
          </div>
        </div>
        <img
          src="img/light.png"
          class="wow fadeIn infinite bg_img"
          data-wow-duration="3s"
          alt=""
        >
        <img
          src="img/shadow.png"
          class="wow fadeIn infinite bg_img"
          alt=""
        >
        <img
          src="img/fish04.png"
          class="wow slideInRight fish01"
          data-wow-duration="2s"
          alt=""
        >
        <div id="particles-js" />
      </div>
    </div>
    <div class="bg_gradient">
      <!-- info -->
      <section
        id="info"
        class="section_info"
      >
        <div class="container">
          <div class="row gx-0 gx-lg-5 mb-5 align-items-center">
            <div class="col-lg-7 wow fadeInLeft">
              <h2 class="h2">
                The Origin
              </h2>
              <p>
                Thousands of years ago, Atlantis, the mysterious and advanced
                civilization, sank beneath the waves due to unknown disasters,
                leaving legends about ancient mystical powers and creatures. As
                time goes by, modern people generally believe that the lore of
                Atlantis is just one of those made-up metaphors by ancient
                philosophers to warn the people back then and try to make their
                society better; I mean, no educated grown-ups would fall into
                these tales, right?
              </p>
              <p>
                However, we were wrong. Everything simply changes by the end of
                2022, right at the moment when the great Poseidon rises from the
                Atlantic Ocean with his elite troops, mermaids, high-end
                mechanics, and even aquatic dinosaurs; humans finally realize
                that the epic Atlantis is not only real but has already
                developed to a level beyond our imagination.
              </p>
              <p>
                “Behold! People on the land!” Poseidon speaks, with his voice
                loud and solemn, “We come in peace, and mean no harm.” He took a
                quick peek at our united national army. “Yet we’ve been watching
                you and notice you’ve been trapped in a seemingly endless cycle
                of lies and suspicions, leading the planet to its grave danger…”
                He speaks in agony. “That’s why we decided to rise and share our
                valuable governing experiences and latest technology with you,
                for all of us can make this planet a better place.”
              </p>
              <p>
                Thus, the Atlantis-P convention has been signed between the
                United Nation and the great Atlantis, marking the dawn of a new
                age in human history, an era that we people utilize Atlantean
                mystical powers to enhance our lives and more importantly, our
                collective consciousness, making our life on earth better.
              </p>
            </div>
            <div class="col-lg-5 wow fadeInRight">
              <div class="home_shell float">
                <img
                  src="img/shell/1.png"
                  class="shell01"
                  alt=""
                >
                <img
                  src="img/shell/2.png"
                  class="shell02 wow fadeIn infinite"
                  data-wow-duration="7s"
                  alt=""
                >
                <img
                  src="img/shell/3.png"
                  class="shell03 wow flash infinite"
                  data-wow-duration="5s"
                  alt=""
                >
                <img
                  src="img/shell/4.png"
                  class="shell04 wow fadeIn infinite"
                  data-wow-duration="5s"
                  alt=""
                >
                <img
                  src="img/shell/5.png"
                  class="shell05"
                  alt=""
                >
                <img
                  src="img/shell/6.png"
                  class="shell06 wow fadeIn infinite"
                  data-wow-duration="1s"
                  alt=""
                >
                <img
                  src="img/shell/7.png"
                  class="shell07"
                  alt=""
                >
                <img
                  src="img/shell/8.png"
                  class="shell08 wow flash infinite"
                  data-wow-duration="6s"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div class="row gx-0 gx-lg-5 align-items-center">
            <div class="col-lg-7 order-2 order-lg-1 wow fadeInLeft">
              <img
                src="img/about.png"
                class="rounded w-100 float"
                alt=""
              >
            </div>
            <div class="col-lg-5 order-1 order-lg-2 wow fadeInRight">
              <h2 class="h2">
                The Genesis in Metaverse
              </h2>
              <p>A New Age in Metaverse: Manifesto of Poseidon</p>

              <p>Hi, Land People,</p>

              <p>
                Atlantis-P has chosen many among you to inherit our old but
                powerful mystical powers. 100+ of you have already become
                Atlantean sorcerers and wizards and created fascinating NFTs
                through their artistic spirits. According to the Atlantis-P
                convention, we promise to build a strong art community for
                artists to be seen in Web3.0. Holding one of these NFTs shows
                your support and grants you citizenship to our Atlantean
                Metaverse where you can enjoy various on-chain and off-chain
                utilities and connect with artists worldwide.
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- team -->
      <section
        id="team"
        class="section_team"
      >
        <img
          src="img/fish03.png"
          class="wow fadeIn fish02"
          data-0="transform:translate(0%,0%)"
          data-top-bottom="transform:translate(120px,-150px)"
          alt=""
        >
        <div class="container">
          <div class="text-center mb-5 wow fadeInUp">
            <h2 class="h2">
              We are…
            </h2>
          </div>
          <div class="position-relative">
            <div class="swiper wow fadeInUp">
              <div class="swiper-wrapper">
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/01.jpg')"
                    />
                    <div class="job_name">
                      Director
                    </div>
                    <div class="name">
                      M
                    </div>
                  </div>
                </div>
                <!-- item -->
                <!-- <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/03.jpg')"
                    />
                    <div class="job_name">
                      Consultant
                    </div>
                    <div class="name">
                      Eric
                    </div>
                  </div>
                </div> -->
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/04.jpg')"
                    />
                    <div class="job_name">
                      Main Artist
                    </div>
                    <div class="name">
                      Hot Dog life
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/05.jpg')"
                    />
                    <div class="job_name">
                      Main Artist/ Writer
                    </div>
                    <div class="name">
                      Erick Yang
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/06.jpg')"
                    />
                    <div class="job_name">
                      Main Artist
                    </div>
                    <div class="name">
                      Yu Rin
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/07.jpg')"
                    />
                    <div class="job_name">
                      IP designer
                    </div>
                    <div class="name">
                      Yen
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/08.jpg')"
                    />
                    <div class="job_name">
                      Art consultant
                    </div>
                    <div class="name">
                      C.long
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination" />
            </div>
            <div class="swiper-button-prev d-none d-md-block" />
            <div class="swiper-button-next d-none d-md-block" />
          </div>
        </div>
      </section>
      <!-- Arts -->
      <section
        id="arts"
        class="section_arts"
      >
        <div class="container">
          <div class="text-center mb-5 wow fadeInUp">
            <h2 class="h2">
              ​The Arts
            </h2>
            <p>
              Contrary to conventional NFT projects, which are randomly
              generated with different characteristics, Atlantis-P invited more
              than 100 famous artists to create based on the template of the 4
              main characters in the Atlantis Kingdom, Each NFT is a unique work
              of art dedicated by a true artist.
            </p>
          </div>
          <div class="row gx-2 gx-sm-3 gx-lg-4 mb-5 wow fadeInUp">
            <!-- <div class="col-lg-3 col-md-4 col-6 mb-3 mb-lg-4">
            <div class="art_item">
              <div class="image" style="background-image: url('img/arts/01.jpg');">
                <div class="cover">
                  <a href="#" class="btn btn-primary mb-3">MINT</a>
                  <a href="#" class="btn btn-outline-light">VIEW</a>
                </div>
              </div>
              <div class="info">
                <div class="name">Artist name</div>
                <div class="title">大威德明王</div>
              </div>
            </div>
          </div> -->
            <div
              v-for="item in artList"
              :key="item.id"
              class="col-lg-3 col-md-4 col-6 mb-2 gx-sm-3 mb-lg-4"
            >
              <div class="art_item">
                <div
                  class="image"
                  :style="{ backgroundImage: 'url(' + item.img + ')' }"
                >
                  <div class="cover">
                    <!-- <a href="#" class="btn btn-primary mb-3" id="item.id">MINT</a> -->
                    <a
                      data-fancybox="gallery"
                      :href="item.img"
                      class="btn btn-outline-light"
                    >VIEW</a>
                  </div>
                </div>
                <div class="info">
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="text-center">
            <a href="arts" class="btn btn-outline-light btn-lg px-5">MORE</a>
          </div> -->
        </div>
      </section>
      <!-- roadmap -->
      <section
        id="roadmap"
        class="section_roadmap"
      >
        <div class="container">
          <div class="text-center mb-5">
            <h2 class="h2 wow fadeInUp">
              Roadmap
            </h2>
          </div>
          <div class="row">
            <div class="col-md-10 m-auto wow fadeInUp">
              <div class="roadmap">
                <ul class="list-unstyled list_roadmap">
                  <li class="wow fadeInUp">
                    <h4>Stage 1</h4>
                    <p>
                      When Over 10% of Atlantis-P NFTs are minted, a brand new
                      race will be added to our Metaverse. A new wave of open
                      auditions will be announced by the council, for more
                      talented sorcerers and wizards to unleash their creativity
                      in this Atlantean Kingdom.
                    </p>
                  </li>
                  <li class="wow fadeInUp">
                    <h4>Stage 2</h4>
                    <p>
                      When Over 30% of Atlantis-P NFTs are minted, the council
                      will release 100 rare pets in total to our citizens’
                      wallets.
                    </p>
                  </li>
                  <li class="wow fadeInUp">
                    <h4>Stage 3</h4>
                    <p>
                      When Over 50% of Atlantis-P NFTs are minted, the council
                      will launch the official Atlantis-P gallery in the
                      physical world, where all the citizens can make
                      connections and participate in our quality artistic
                      events.
                    </p>
                  </li>
                  <li class="wow fadeInUp">
                    <h4>Stage 4</h4>
                    <p>
                      When Over 75% of Atlantis-P NFTs are minted, multiple
                      utilities will be activated for all our citizens. Every
                      citizen can indulge themselves with different rewards
                      according to the ranks of Atlantis-P NFTs they hold and be
                      prepared for more upcoming utilities await in the future
                      ;)
                    </p>
                  </li>
                  <li class="wow fadeInUp">
                    <h4>Stage 5</h4>
                    <p>
                      When all Atlantis-P NFTs are minted, the council will hold
                      the very first Atlantis-P summit at our official gallery.
                      By the time all the sorcerers, wizards, and citizens
                      worldwide will gather at this grand party and the next big
                      step of Atlantis-P will finally be announced!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- faq -->
      <section
        id="faq"
        class="section_faq"
      >
        <div class="container">
          <div class="text-center mb-5">
            <h2 class="h2 wow fadeInUp">
              Frequently Asked Question
            </h2>
          </div>
          <div class="row">
            <div class="col-md-10 m-auto wow fadeInUp">
              <div
                id="accordionExample"
                class="accordion"
              >
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01"
                  >
                    What is Atlantis-P NFT ?
                  </h3>
                  <div
                    id="faq01"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        It's a digital asset on Ethereum. The holders can use it
                        to enjoy the utility of the Atlantis Metaverse
                      </p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq02"
                  >
                    How much is the price?
                  </h3>
                  <div
                    id="faq02"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>0.08ETH</p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq03"
                  >
                    Does rarity matter?
                  </h3>
                  <div
                    id="faq03"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        Yes, by every means. <br>
                        From the highest rank to the lowest:
                        Royal、aristocrat、priest、Senator、Citizen
                      </p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq04"
                  >
                    When sale?
                  </h3>
                  <div
                    id="faq04"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>2022/8/19 2000</p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq05"
                  >
                    Where sale?
                  </h3>
                  <div
                    id="faq05"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>OpenSea</p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq06"
                  >
                    Which wallet can I use?
                  </h3>
                  <div
                    id="faq06"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>Metamask</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- footer -->
      <footer class="footer">
        <div class="container text-center">
          <div>Atlantis -P © 2022</div>
        </div>
        <img
          src="img/footer.png"
          class="footer_img"
          alt=""
        >
      </footer>
      <!-- Connect Modal -->
      <div
        id="connectModal"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="connectModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="connectModalLabel"
                class="modal-title"
              >
                Connect
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body">
              <ul class="wallet-list">
                <li>
                  <img
                    src="img/metamask.png"
                    alt=""
                  >
                  Meta Mask
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Atlantis',
  data() {
    return {
      artList: [
        {
          id: '01',
          img: 'img/arts/01.jpg',
          name: '薛玉翎 YuRin-110',
          title: '青蛙小農',
        },
        {
          id: '02',
          img: 'img/arts/02.jpg',
          name: '蘇恩 熱狗日常',
          title: '波賽頓',
        },
        {
          id: '03',
          img: 'img/arts/03.jpg',
          name: '葉倢伃',
          title: '紫珊瑚魔女',
        },
        {
          id: '04',
          img: 'img/arts/04.jpg',
          name: '葉姿宜',
          title: '提格瑞',
        },
        {
          id: '05',
          img: 'img/arts/05.jpg',
          name: '林晏羽',
          title: '銀河',
        },
        {
          id: '06',
          img: 'img/arts/06.jpg',
          name: '蔡佳展 Q淡-40',
          title: '木屬性的亞特蘭提斯聖人',
        },
        {
          id: '07',
          img: 'img/arts/07.jpg',
          name: '楊知誠 惡魔腦多巴胺分泌',
          title: 'Sea Banshee Atlana',
        },
        {
          id: '08',
          img: 'img/arts/08.jpg',
          name: '蕭宇筑 小雨竹',
          title: '怪獸操控守衛機器人',
        },
        {
          id: '09',
          img: 'img/arts/09.jpg',
          name: '陳奕龍 野生阿宅',
          title: '九天玄女',
        },
        {
          id: '10',
          img: 'img/arts/10.jpg',
          name: '楊書府',
          title: '深山黑面虎爺將軍',
        },
        {
          id: '11',
          img: 'img/arts/11.jpg',
          name: '陳麗昕',
          title: 'stingray',
        },
        {
          id: '12',
          img: 'img/arts/12.jpg',
          name: '昴瞇',
          title: 'Guard',
        },
        {
          id: '13',
          img: 'img/arts/13.jpg',
          name: '謝宇農 Jerry',
          title: 'BASKEFOR LIFE',
        },
        {
          id: '14',
          img: 'img/arts/14.jpg',
          name: '洵之Shikaya',
          title: '松果探測機',
        },
        {
          id: '15',
          img: 'img/arts/15.jpg',
          name: '鄭婷之',
          title: '星之警衛',
        },
        {
          id: '16',
          img: 'img/arts/16.jpg',
          name: '凱西',
          title: '河豚戰士',
        },
        {
          id: '17',
          img: 'img/arts/17.jpg',
          name: '周秘羽',
          title: '咖衛',
        },
        {
          id: '18',
          img: 'img/arts/18.jpg',
          name: 'ENF',
          title: '魚乾號',
        },
        {
          id: '19',
          img: 'img/arts/19.jpg',
          name: '林彥婷',
          title: '人魚茉莉',
        },
        {
          id: '20',
          img: 'img/arts/20.jpg',
          name: '蘇恩 熱狗日常',
          title: '人魚',
        },
      ],
      btnMintDisabled: false,
    }
  },
  methods: {
    async Mint(aContract, signature, count) {
      try {
        return await aContract.whitelistMint(signature, 1, {
          value: `${count[1].toString()}`,
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async translate(tx) {
      try {
        const receipt = await tx.wait()
        this.$toast.success('blockHash: ' + receipt.blockHash)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  },
}
</script>

<style scoped></style>
